import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appEmbedTweet]'
})
export class EmbedTweetDirective {

  option: number;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {
    console.log(this);
  }

  @Input() set appEmbedTweet(option) {
    this.option = option;
  }

}
