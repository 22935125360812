import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {IUserPostSection} from '../../../../@types/post';

@Component({
  selector: 'app-single-photo',
  templateUrl: './single-photo.component.html',
  styleUrls: ['./single-photo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SinglePhotoComponent implements OnInit {

  @Input() editMode: boolean;

  @Input() section: IUserPostSection;

  @Output() remove = new EventEmitter<IUserPostSection>();

  @Output() up = new EventEmitter<IUserPostSection>();

  @Output() down = new EventEmitter<IUserPostSection>();

  constructor() {
    // console.log(this.section);
  }

  changePosition(x: any) {
    this.section.singlePhoto.position = x;
  }

  ngOnInit(): void {
    console.log(this);
  }

  moveUp() {
    this.up.emit(this.section);
  }

  moveDown() {
    this.down.emit(this.section);
  }

  removeSection() {
    this.remove.emit(this.section);
  }

}
