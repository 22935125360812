<div class="single-content-section-simpletext" [ngClass]="{'edit-mode':editMode}">

  <ul *ngIf="editMode" class="settings">
    <li>
      <a (click)="removeSection()" class="btn btn-danger">
        <i class=" fa fa-trash text-white"></i>
      </a>
    </li>

    <li>
      <a (click)="moveUp()" class="btn btn-info">
        <i class=" fa fa-arrow-up text-white"></i>
      </a>
    </li>

    <li>
      <a (click)="moveDown()" class="btn btn-info">
        <i class=" fa fa-arrow-down text-white"></i>
      </a>
    </li>

  </ul>

  <!--  <fate-ui uiId="foo"></fate-ui>-->

  <textarea class="ant-input text-title text-center" placeholder="H2 YAZI BAŞLIĞI (OPTIONAL)"
            *ngIf="editMode"
            (input)="oninput($event)"
            [(ngModel)]="section.simpleTxt.title"></textarea>

  <h2 *ngIf="!editMode" class="text-title  break-word">
    {{section.simpleTxt.title}}
  </h2>

  <textarea class="ant-input text-summary text-center" placeholder="Hikayeyi yazmak için buradan başlayabilirsin! :)"
            *ngIf="editMode"
            (input)="oninput($event)"
            [(ngModel)]="section.simpleTxt.summary"></textarea>

  <h2 *ngIf="!editMode" class="text-summary  break-word">
    {{section.simpleTxt.summary}}
  </h2>

  <!--  <fate-input *ngIf="editMode" customClass="title" placeholder="H2 YAZI BAŞLIĞI (OPTIONAL)"-->
  <!--              [(ngModel)]="section.simpleTxt.title"></fate-input>-->

  <!--  <div *ngIf="!editMode" class="text-title" [innerHTML]="section.simpleTxt.title"></div>-->

  <!--  <fate-input *ngIf="editMode" customClass="summary"-->
  <!--              placeholder="Hikayeyi yazmak için buradan başlayabilirsin! :)"-->
  <!--              [(ngModel)]="section.simpleTxt.summary"></fate-input>-->

  <!--  <div *ngIf="!editMode" class="text-summary" [innerHTML]="section.simpleTxt.summary"></div>-->


  <!--  <textarea class="single-content-title text-area"-->
  <!--            [disabled]="!editMode"-->
  <!--            rows="1"-->
  <!--            type="text"-->
  <!--            placeholder="H2 YAZI BAŞLIĞI (OPTIONAL)" [(ngModel)]="section.simpleTxt.title"></textarea>-->

  <!--  <textarea [disabled]="!editMode"-->
  <!--            class="single-content-paragraph text-area" rows="1" type="text"-->
  <!--            placeholder="Hikayeyi yazmak için buradan başlayabilirsin! :)"-->
  <!--            [(ngModel)]="section.simpleTxt.summary"></textarea>-->


</div>


