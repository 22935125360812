import {Component, Input, OnInit} from '@angular/core';
import {IUserPostSection} from '../../../../../@types/post';
import {UploadService} from '../../../../../_services/upload.service';

@Component({
  selector: 'app-single-photo-image-area',
  templateUrl: './single-photo-image-area.component.html',
  styleUrls: ['./single-photo-image-area.component.scss']
})
export class SinglePhotoImageAreaComponent implements OnInit {

  @Input() editMode: boolean;

  @Input() section: IUserPostSection;

  constructor(private us: UploadService) {
  }

  ngOnInit(): void {
  }


  removeImage() {
    if (this.section && this.section.singlePhoto) {
      delete this.section.singlePhoto.image;
    }
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const file: File = files[0];

    if (files && file) {
      this.section.uploadProgress = true;
      this.section.uploadValue = 5;

      const interval = setInterval(() => {

        if (this.section.uploadValue < 90) {
          this.section.uploadValue = this.section.uploadValue + 5;
        }

      }, 500);

      this.us.upload(file).subscribe((resp) => {
        clearInterval(interval);
        this.section.uploadValue = 100;
        setTimeout(() => {
          this.section.uploadProgress = false;
          setTimeout(() => {
            this.section.singlePhoto.image = resp;
          }, 100);
        }, 100);
      });
    }
  }
}
