import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {

  private token = 'eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJwb3p5LWJhY2tlbmQtYXBwIiwic3ViIjoiMTciLCJpYXQiOjE1ODkzOTY0MTMsImV4cCI6MTU5MDg2NzY0Mn0.tOleTyvSCoB7sUI3003vsIyORNRItAnDN9sfjuGk3Ps5FXV3tHwU-tmMXmADvbhAfUsHvVRzqZF6yz17MvaSAg';

  constructor(private router: Router) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      // this.router.navigateByUrl(`/login`);
      window.location = <any> environment.pozyMainUrl;
      return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cloneReq;
    if (this.token && !environment.production) {
      cloneReq = req.clone({
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.token
        })
      });
    } else {
      cloneReq = req;
    }
    return next.handle(cloneReq).pipe(catchError(x => this.handleAuthError(x)));
  }
}
