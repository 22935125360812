import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PostService} from '../../_services/post.service';
import {IUserPost, IUserPostSection} from '../../@types/post';
import {UserService} from '../../_services/user.service';
import {CategoryService} from '../../_services/category.service';
import {PhotoPosition, PostSectionType} from '../../@types/enum';
import {UploadService} from '../../_services/upload.service';
import {forkJoin} from 'rxjs';
import {ToastService} from '../../_services/toast.service';
import * as $ from 'jquery';
import autosize from 'autosize';

@Component({
  selector: 'app-write-content',
  templateUrl: './write-content.component.html',
  styleUrls: ['./write-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WriteContentComponent implements OnInit {

  editMode = true;
  public profileSummary: IUserProfileSummary;
  public post: IUserPost;
  public categories: ICategory[];
  public selectedCategory: string;
  lastCreatedSingleSection: IUserPostSection;
  lastCreatedMultiSection: IUserPostSection;

  constructor(private route: ActivatedRoute, private postService: PostService,
              private us: UserService, private cs: CategoryService, private imageUpload: UploadService,
              private uploadService: UploadService, private router: Router, private ts: ToastService) {
  }

  ngOnInit() {
    this.cs.categoryList().subscribe((resp) => {
      this.categories = resp;
    });
    if (this.route.snapshot.params.id) {
      this.postService.loadUserPost(this.route.snapshot.params.id).subscribe((resp) => {
        console.log(resp);
        this.post = resp;

        if (this.post.status === 'WAITING_APPROVAL' || this.post.status === 'PUBLISHED') {
          this.editMode = false;
        }
        if (this.categories) {
          const category = this.categories.filter((x) => {
            return x.id === this.post.categoryId;
          })[0];
          this.selectedCategory = category.name;
        }
      });
    } else {
      console.log('yeni icerik');
      this.post = {
        sectionList: [
          {
            type: PostSectionType.SIMPLE_TEXT,
            simpleTxt: {}
          },
          {
            type: PostSectionType.EMBED,
            embed: {}
          }]
      };
    }

    this.us.profileSummary().subscribe((x) => {
      this.profileSummary = x;
    });

    this.us.revokeProfileSummary();
  }

  addSection(x: any) {
    let section: IUserPostSection;
    if (x === PostSectionType.SINGLE_PHOTO) {
      section = {
        type: PostSectionType.SINGLE_PHOTO,
        singlePhoto: {
          position: PhotoPosition.TOP
        }
      };
      this.lastCreatedSingleSection = section;
    } else if (x === PostSectionType.PHOTO_GALLERY) {
      section = {
        type: PostSectionType.PHOTO_GALLERY,
        photoGallery: {
          imageList: []
        }
      };
      this.lastCreatedMultiSection = section;
    } else if (x === PostSectionType.SIMPLE_TEXT) {
      section = {
        type: x,
        simpleTxt: {}
      };
    } else if (x === PostSectionType.EMBED) {
      section = {
        type: x,
        embed: {}
      };
    } else {
      throw {msg: 'not implemented sectino type : ' + x};
    }

    this.post.sectionList.push(section);
  }

  selectCategory(category: ICategory) {
    this.selectedCategory = category.name;
    this.post.categoryId = category.id;
  }

  toggleMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      setTimeout(() => {
        autosize($('textarea'));
      });
    }
  }

  saveAllChanges() {
    this.postService.save(this.post).subscribe((x) => {
      this.router.navigateByUrl('/app/anasayfa/taslaklar');
      this.ts.showSuccess('İçerik Kaydedildi.');
    });
  }

  sendApprove() {
    this.postService.sendApprove(this.post.id).subscribe(() => {
      console.log('saved');
      this.router.navigateByUrl('/app/anasayfa/onay-bekleyenler');
      this.ts.showSuccess('İçerik Onaya Gönderildi.');
    });
  }

  saveAndApprove() {
    this.postService.save(this.post).subscribe((x) => {
      this.postService.sendApprove(x.postId).subscribe(() => {
        this.router.navigateByUrl('/app/anasayfa/onay-bekleyenler');
        this.ts.showSuccess('İçerik Kaydedildi ve Onaya Gönderildi.');
      });
    });
  }

  oninput($event) {
    autosize($($event.target));
  }

  handleSingleSelect(evt) {
    const files = evt.target.files;
    const file: File = files[0];

    if (files && file) {
      this.lastCreatedSingleSection.uploadProgress = true;
      this.lastCreatedSingleSection.uploadValue = 5;

      const interval = setInterval(() => {

        if (this.lastCreatedSingleSection.uploadValue < 90) {
          this.lastCreatedSingleSection.uploadValue = this.lastCreatedMultiSection.uploadValue + 5;
        }

      }, 500);

      this.imageUpload.upload(file).subscribe((resp) => {
        clearInterval(interval);
        this.lastCreatedSingleSection.uploadValue = 100;
        setTimeout(() => {
          this.lastCreatedSingleSection.uploadProgress = false;
          setTimeout(() => {
            this.lastCreatedSingleSection.singlePhoto.image = resp;
          }, 100);
        }, 100);
      });
    }
  }

  handleMultiSelect(evt) {
    const files = evt.target.files;
    console.log(files);
    if (this.lastCreatedMultiSection.photoGallery.imageList.length === 6) {
      return;
    }
    const allowedTotal = 6;
    const uploadSize = files.length;
    const currentSize = this.lastCreatedMultiSection.photoGallery.imageList.length;

    if (currentSize + uploadSize > allowedTotal) {
      return;
    }

    if (files) {
      const arr = [];

      for (let i = 0; i < 6; i++) {
        if (files[i]) {
          arr.push(this.imageUpload.upload(files[i]));
        }
      }

      console.log(arr);
      this.lastCreatedMultiSection.uploadProgress = true;
      this.lastCreatedMultiSection.uploadValue = 5;

      const interval = setInterval(() => {

        if (this.lastCreatedMultiSection.uploadValue < 90) {
          this.lastCreatedMultiSection.uploadValue = this.lastCreatedMultiSection.uploadValue + 5;
        }

      }, 750);

      forkJoin(arr).subscribe((rs) => {
        clearInterval(interval);
        this.lastCreatedMultiSection.uploadValue = 100;
        setTimeout(() => {
          this.lastCreatedMultiSection.uploadProgress = false;
          setTimeout(() => {
            this.lastCreatedMultiSection.photoGallery.imageList = this.lastCreatedMultiSection.photoGallery.imageList.concat(<any> rs);
          }, 100);
        }, 100);
      });
    }
  }


  moveUp(section: IUserPostSection): void {
    console.log('up ', section);
    const currentIndex = this.post.sectionList.indexOf(section);
    this.array_move(this.post.sectionList, currentIndex, currentIndex - 1);
  }

  moveDown(section?: IUserPostSection): void {
    console.log('down ', section);
    const currentIndex = this.post.sectionList.indexOf(section);
    this.array_move(this.post.sectionList, currentIndex, currentIndex + 1);
  }

  removeSection(section: IUserPostSection): void {
    this.post.sectionList.splice(this.post.sectionList.indexOf(section), 1);
  }


  array_move(arr: any[], oldIndex: number, newIndex: number) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr; // for testing
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const file: File = files[0];

    if (files && file) {
      this.uploadService.upload(file).subscribe((resp) => {
        this.post.imageUrl = resp.url;
      });
    }
  }

}
