<div *ngIf="settings" class="profile-settings">
  <div class="profile-settings-image col-md-12">
    <div class="row">
      <div class="profile-settings-image-preview col-md-2"><img class="img-responsive" [src]="settings.sourceUrl"
                                                                alt=""></div>
      <div class="profile-settings-image-description col-md-3"><h2>Kaynak Fotoğrafı</h2>
        <p>Kaynak fotoğrafı minimum genişliği 500px olup, 3 MB’ın altında olmalıdır.</p><label
          class="btn button">Dosya Seçin <input type="file" (change)="handleFileSelect($event)" hidden=""></label></div>
    </div>
  </div>
  <div class="profile-settings-form col-md-12">
    <form>
      <div class="form-group "><label>Kayna Goruntulenme İsmi</label><label class="error-message"></label><input
        name="name" class="form-control" [(ngModel)]="settings.sourceName"></div>
      <div class="form-group "><label>Kaynak</label><label class="error-message"></label><input
        name="username" class="form-control" [(ngModel)]="settings.sourceRoute"><span>https://www.pozy.org/kaynak</span>
      </div>
      <div class="form-group "><label>Kişisel Websitesi</label><label class="error-message"></label><input
        name="website" class="form-control" [(ngModel)]="settings.website"></div>
      <div class="form-group "><label>Twitter Kullanıcı Adı</label><label class="error-message"></label><input
        name="twitterUsername" class="form-control" [(ngModel)]="settings.twitter"></div>
      <div class="form-group "><label>Facebook Kullanıcı Adı</label><label class="error-message"></label><input
        name="facebookUsername" class="form-control" [(ngModel)]="settings.facebook"></div>
      <div class="form-group "><label>Instagram Kullanıcı Adı</label><label class="error-message"></label><input
        name="instagramUsername" class="form-control" [(ngModel)]="settings.instagram"></div>
      <div class="form-group "><label>LinkedIn Kullanıcı Adı</label><label class="error-message"></label><input
        name="linkedinUsername" class="form-control" [(ngModel)]="settings.linkedin"></div>
      <button type="button" (click)="save()" class="col-md-12 col-xs-12 profile-settings-form-save btn btn-primary">
        KAYDET
      </button>
    </form>
  </div>
</div>
