<textarea class="ant-input text-title text-center" placeholder="H2 YAZI BAŞLIĞI (OPTIONAL)"
          *ngIf="editMode"
          (input)="oninput($event)"
          [(ngModel)]="section.singlePhoto.title"></textarea>

<h2 *ngIf="!editMode" class="text-title  break-word">
  {{section.singlePhoto.title}}
</h2>

<textarea class="ant-input text-summary text-center" placeholder="Hikayeyi yazmak için buradan başlayabilirsin! :)"
          *ngIf="editMode"
          (input)="oninput($event)"
          [(ngModel)]="section.singlePhoto.summary"></textarea>

<h2 *ngIf="!editMode" class="text-summary  break-word">
  {{section.singlePhoto.summary}}
</h2>
