import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {WriteContentComponent} from './write-content.component';

const writeContentRoutes = [
  {
    path: ':id', component: WriteContentComponent
  },
  {
    path: '', component: WriteContentComponent
  }
];

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forChild(writeContentRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class WriteContentRoutingModule {
}
