import {Injectable, TemplateRef} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({textOrTpl, ...options});
  }

  showSuccess(text: string) {
    this.show(text, {classname: 'bg-success text-light', delay: 5000});
  }

  // this.toastService.show('I am a success toast', { classname: 'bg-success text-light', delay: 10000 });
  // this.toastService.show(dangerTpl, { classname: 'bg-danger text-light', delay: 15000 });

// <ng-template #dangerTpl>
// <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24"
//   Danger Danger !
// </ng-template>

  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
