import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private http: HttpClient, @Inject('environment.apiURL') private apiURL: string) {
  }

  profileSettings(): Observable<IProfileSettings> {
    return <any> this.http.get(this.apiURL + '/setting/profile');
  }

  sourceSettings(): Observable<ISourceSettings> {
    return <any> this.http.get(this.apiURL + '/setting/source');
  }

  saveProfileSettings(x: IProfileSettings): Observable<void> {
    return <any> this.http.put(this.apiURL + '/setting/profile', x);
  }

  saveSourceSettings(x: ISourceSettings): Observable<void> {
    return <any> this.http.put(this.apiURL + '/setting/source', x);
  }

}
