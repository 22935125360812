import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../_services/user.service';


@Injectable()
export class AuthGuard implements CanActivate {

  private simpleUser: ISimpleUser;

  constructor(private router: Router, private userServiceService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((sub) => {
      if (this.simpleUser) {
        sub.next(true);
        sub.complete();
      } else {
        this.userServiceService.me(true).subscribe((user) => {
          this.simpleUser = user;
          sub.next(true);
          sub.complete();
        });
      }
    });
  }
}
