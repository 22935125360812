import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IPostSummary, IUserPost, IUserPostPersistResult} from '../@types/post';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private http: HttpClient, @Inject('environment.apiURL') private apiURL: string) {
  }

  activePosts(): Observable<IPostSummary[]> {
    return <any> this.http.get(`${this.apiURL}/userPost/activePosts`);
  }

  draftPosts(): Observable<IPostSummary[]> {
    return <any> this.http.get(`${this.apiURL}/userPost/draftPosts`);
  }

  approvalPosts(): Observable<IPostSummary[]> {
    return <any> this.http.get(`${this.apiURL}/userPost/waitingApprovalPosts`);
  }

  loadUserPost(id: number): Observable<IUserPost> {
    return <any> this.http.get(`${this.apiURL}/userPost/${id}`);
  }

  save(x: IUserPost): Observable<IUserPostPersistResult> {
    return <any> this.http.post(`${this.apiURL}/userPost`, x);
  }

  sendApprove(id: number) {
    return <any> this.http.post(`${this.apiURL}/userPost/${id}/approve`, {});
  }

}
