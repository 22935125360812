<div *ngIf="!section.singlePhoto.image" class="inputWrapper">
  <input class="fileInput" (change)="handleFileSelect($event)"
         accept="image/x-png, image/png, image/jpg, image/jpeg, image/gif" type="file"
         autocomplete="off">Bir fotoğraf ekleyin, buraya sürükleyip bırakın
  veya yüklemek için tıklayın
</div>
<div *ngIf="section.singlePhoto.image" class="gallery-item "><img
  [src]="section.singlePhoto.image.url"
  width="100%" height="100%" alt="">
  <div *ngIf="editMode" class="single-content-gallery-button-area">
    <div class="button-area-bottom">
      <button (click)="removeImage()" class="btn btn-danger"><i class="fa fa-trash"></i>Sil</button>
    </div>
  </div>
</div>
