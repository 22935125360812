<div class="single-content-section-single-photo" [ngClass]="{'edit-mode':editMode}">

  <ul *ngIf="editMode" class="settings">
    <li>
      <a (click)="removeSection()" class="btn btn-danger">
        <i class=" fa fa-trash text-white"></i>
      </a>
    </li>

    <li>
      <a (click)="moveUp()" class="btn btn-info">
        <i class=" fa fa-arrow-up text-white"></i>
      </a>
    </li>

    <li>
      <a (click)="moveDown()" class="btn btn-info">
        <i class=" fa fa-arrow-down text-white"></i>
      </a>
    </li>
    <li>
      <a (click)="changePosition('TOP')" class="btn btn-light">
        <img src="assets/images/align-center.843e8b42.svg">
      </a>
    </li>

    <li>
      <a (click)="changePosition('LEFT')" class="btn btn-light">
        <img src="assets/images/align-left.29c50989.svg">
      </a>
    </li>

    <li>
      <a (click)="changePosition('RIGHT')" class="btn btn-light">
        <img src="assets/images/align-right.c3fb6944.svg">
      </a>
    </li>

    <li>
      <a (click)="changePosition('BOTTOM')" class="btn btn-light">
        <img src="assets/images/align-bottom.32276666.svg">
      </a>
    </li>
  </ul>

  <p *ngIf="section.uploadProgress">
    <ngb-progressbar type="success" [value]="section.uploadValue" [striped]="true"></ngb-progressbar>
  </p>

  <div class="d-flex" *ngIf="section.singlePhoto.position==='LEFT'">
    <div class="col-md-6">
      <app-single-photo-image-area [section]="section" [editMode]="editMode"></app-single-photo-image-area>
    </div>

    <div class="col-md-6">
      <app-single-photo-text-area [section]="section" [editMode]="editMode"></app-single-photo-text-area>
    </div>

  </div>

  <div class="top-and-bottom" *ngIf="section.singlePhoto.position==='TOP'">
    <div class="col">
      <app-single-photo-image-area [section]="section" [editMode]="editMode"></app-single-photo-image-area>
    </div>

    <div class="clearfix"></div>

    <div class="col">
      <app-single-photo-text-area [section]="section" [editMode]="editMode"></app-single-photo-text-area>
    </div>

  </div>

  <div class="top-and-bottom" *ngIf="section.singlePhoto.position==='BOTTOM'">
    <div class="col">
      <app-single-photo-text-area [section]="section" [editMode]="editMode"></app-single-photo-text-area>
    </div>

    <div class="clearfix"></div>

    <div class="col">
      <app-single-photo-image-area [section]="section" [editMode]="editMode"></app-single-photo-image-area>

    </div>

  </div>

  <div class="d-flex" *ngIf="section.singlePhoto.position==='RIGHT'">
    <div class="col-md-6">
      <app-single-photo-text-area [section]="section" [editMode]="editMode"></app-single-photo-text-area>
    </div>

    <div class="col-md-6">
      <app-single-photo-image-area [section]="section" [editMode]="editMode"></app-single-photo-image-area>

    </div>

  </div>

</div>
