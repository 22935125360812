<div class="single-content-section-photoGallery" [ngClass]="{'edit-mode':editMode}">

  <ul *ngIf="editMode" class="settings">
    <li>
      <a (click)="removeSection()" class="btn btn-danger">
        <i class=" fa fa-trash text-white"></i>
      </a>
    </li>

    <li>
      <a (click)="moveUp()" class="btn btn-info">
        <i class=" fa fa-arrow-up text-white"></i>
      </a>
    </li>

    <li>
      <a (click)="moveDown()" class="btn btn-info">
        <i class=" fa fa-arrow-down text-white"></i>
      </a>
    </li>

    <li>
      <button [disabled]="this.section.photoGallery.imageList.length ===6" class="btn btn-info inputWrapper">
        <input [disabled]="this.section.photoGallery.imageList.length ===6" class="fileInput"
               (change)="handleFileSelect($event)"
               accept="image/x-png, image/png, image/jpg, image/jpeg, image/gif"
               multiple=""
               type="file" autocomplete="off">
        <i class=" fa fa-plus text-white"></i>
      </button>
    </li>

  </ul>

  <p *ngIf="section.uploadProgress">
    <ngb-progressbar type="success" [value]="section.uploadValue" [striped]="true"></ngb-progressbar>
  </p>

  <textarea class="ant-input text-title text-center" placeholder="H2 YAZI BAŞLIĞI (OPTIONAL)"
            *ngIf="editMode"
            (input)="oninput($event)"
            [(ngModel)]="section.photoGallery.title"></textarea>

  <h2 *ngIf="!editMode" class="text-title  break-word">
    {{section.photoGallery.title}}
  </h2>

  <textarea class="ant-input text-summary text-center" placeholder="Hikayeyi yazmak için buradan başlayabilirsin! :)"
            *ngIf="editMode"
            (input)="oninput($event)"
            [(ngModel)]="section.photoGallery.summary"></textarea>

  <h2 *ngIf="!editMode" class="text-summary  break-word">
    {{section.photoGallery.summary}}
  </h2>

  <div style="position: relative">


    <!--    <div *ngIf="openOverlay" class="overlay">-->

    <!--      <div class="single-content-section single-content-section-image-upload">-->
    <!--        <div class="container">-->
    <!--          <div class="single-content-section">-->
    <!--            <div class="gallery-placeholder"></div>-->
    <!--            <div class="single-content-section-image-upload-text"><p>Fotoğrafı buraya sürükleyip bırakabilir yada-->
    <!--              “Ekle” butonu ile işlem yapabilirsin.</p><span>Maksimum 6 fotoğraf ekleyebilirsin :)</span>-->
    <!--              <ul class="btn-wrapper">-->
    <!--                <li class="">-->
    <!--                  -->
    <!--                </li>-->
    <!--                <li>-->
    <!--                  <button class="btn btn-secondary" *ngIf="editMode" (click)="openOverlay=false">Bitti</button>-->
    <!--                </li>-->
    <!--              </ul>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->


    <div class="row justify-content-center mb-5 gallery-wrapper" dragula="PHOTO_GALLERY"
         [(dragulaModel)]="photoGallery.imageList">

      <div *ngFor="let image of photoGallery.imageList"
           appHoverClass
           [hoverClass]="'hovered'"
           class="gallery-item col-4">
        <img style="height: 100%" [src]="image.url">


        <div class="image-actions">
          <a (click)="removeImage(image)" class="btn btn-danger">
            <i class=" fa fa-trash text-white"></i>
          </a>
        </div>


      </div>

    </div>
  </div>


</div>


