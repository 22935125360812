import {Component, OnInit} from '@angular/core';
import {IPostSummary} from '../../../@types/post';
import {PostService} from '../../../_services/post.service';

@Component({
  selector: 'app-waiting-approval',
  templateUrl: './waiting-approval.component.html'
})
export class WaitingApprovalComponent implements OnInit {

  public posts: IPostSummary[];

  constructor(private ps: PostService) {
  }

  ngOnInit() {
    this.ps.approvalPosts().subscribe((res) => {
      this.posts = res;
      console.log(res);
    });
  }


}
