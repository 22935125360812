import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmbedComponent} from './embed/embed.component';
import {SinglePhotoComponent} from './single-photo/single-photo.component';
import {PhotoGalleryComponent} from './photo-gallery/photo-gallery.component';
import {SimpleTextComponent} from './simple-text/simple-text.component';
import {FormsModule} from '@angular/forms';
import {SinglePhotoTextAreaComponent} from './single-photo/single-photo-text-area/single-photo-text-area.component';
import {SinglePhotoImageAreaComponent} from './single-photo/single-photo-image-area/single-photo-image-area.component';
import {TwitterModule} from '../../../modules/twitter/twitter.module';
import {DragulaModule} from 'ng2-dragula';
import {TemplateModule} from '../../../modules/template/template.module';
import {NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [EmbedComponent, SinglePhotoComponent, PhotoGalleryComponent,
    SimpleTextComponent, SinglePhotoTextAreaComponent, SinglePhotoImageAreaComponent],
  imports: [
    CommonModule,
    FormsModule,
    TwitterModule,
    TemplateModule,
    NgbProgressbarModule,
    DragulaModule.forRoot()
  ],
  exports: [EmbedComponent, SinglePhotoComponent, PhotoGalleryComponent, SimpleTextComponent]
})
export class SectionsModule {
}
