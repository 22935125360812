import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../../../_services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  canPost: boolean;

  constructor(private us: UserService) {
  }

  ngOnInit(): void {
    this.us.me().subscribe((resp) => {
      this.canPost = resp.abilitiesList.indexOf('CAN_POST') > 0;
    });
  }
}
