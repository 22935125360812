<div class="profile-page">
  <section class="profile-cover">
    <div class="container">
      <div class="row">
        <div class="profile-cover-image col-md-2 col-xs-12 text-center"><img [src]="simpleUser.avatarUrl"></div>
        <div class="profile-cover-description col-md-5 col-xs-12 pl-0">
          <h1>{{simpleUser.name}}
<!--            <i><img src="https://pozy.org/assets/images/verified-account.svg"-->
<!--                                                                      alt="">-->
<!--          </i><span-->
<!--            class="author-description-title-year">4 Yıl</span></h1>-->
          </h1>
          <p>
            {{simpleUser.biography}}
          </p>
        </div>

        <div class="profile-cover-information col-md-5 col-xs-12">

          <div class="profile-cover-information-list">
            <div class="profile-cover-information-analytic analytic-pozy">
              <div class="profile-cover-information-analytic-title">Pozylendin!</div>
              <div class="profile-cover-information-analytic-number"><i><img
                src="https://pozy.org/assets/images/smile.svg"
                alt=""></i><span>{{profileSummary.totalPozyCount}}</span></div>
            </div>
            <div class="profile-cover-information-analytic analytic-post">
              <div class="profile-cover-information-analytic-title">İçerik</div>
              <div class="profile-cover-information-analytic-number"><i><img
                src="https://pozy.org/assets/images/web.svg"
                alt=""></i><span>{{profileSummary.totalContentCount}}</span></div>
            </div>
            <div class="profile-cover-information-follow hidden-xs hidden-sm"></div>
          </div>

          <div class="clearfix"></div>

          <div class="profile-cover-information-link pr-0 pt-20">

            <ul class="socials">

              <li *ngIf="profileSummary.originLink"><a href="{{profileSummary.originLink}}" target="_blank"><i
                class="fa fa-link font-26"
                aria-hidden="true"></i></a></li>


              <li *ngIf="profileSummary.facebookLink"><a href="https://www.facebook.com/{{profileSummary.facebookLink}}" target="_blank"><i
                class="fa fa-facebook font-26"
                aria-hidden="true"></i></a></li>


              <li *ngIf="profileSummary.twitterLink"><a href="https://twitter.com/{{profileSummary.twitterLink}}" target="_blank"><i
                class="fa fa-twitter font-26"
                aria-hidden="true"></i></a></li>


              <li *ngIf="profileSummary.instagramLink"><a href="https://www.instagram.com/{{profileSummary.instagramLink}}" target="_blank"><i
                class="fa fa-instagram font-26"
                aria-hidden="true"></i></a></li>


              <li *ngIf="profileSummary.linkedInLink"><a href="https://www.linkedin.com/in/{{profileSummary.linkedInLink}}" target="_blank"><i
                class="fa fa-linkedin font-26" aria-hidden="true"></i></a></li>

            </ul>

          </div>

        </div>
      </div>
    </div>
  </section>
  <div class="ant-tabs ant-tabs-content ant-tabs-content-no-animated ant-tabs-top-content">
    <section class="profile-tab">
      <div class="container">
        <div class="col-md-12 tabbable-line profile-tab-ant">
          <div role="tablist" class="ant-tabs-bar ant-tabs-top-bar" tabindex="0">
            <div class="ant-tabs-nav-container"><span unselectable="unselectable"
                                                      class="ant-tabs-tab-prev ant-tabs-tab-btn-disabled"><span
              class="ant-tabs-tab-prev-icon"><span role="img" aria-label="left"
                                                   class="anticon anticon-left ant-tabs-tab-prev-icon-target"><svg
              viewBox="64 64 896 896" focusable="false" class="" data-icon="left" width="1em" height="1em"
              fill="currentColor" aria-hidden="true"><path
              d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg></span></span></span><span
              unselectable="unselectable" class="ant-tabs-tab-next ant-tabs-tab-btn-disabled"><span
              class="ant-tabs-tab-next-icon"><span role="img" aria-label="right"
                                                   class="anticon anticon-right ant-tabs-tab-next-icon-target"><svg
              viewBox="64 64 896 896" focusable="false" class="" data-icon="right" width="1em" height="1em"
              fill="currentColor" aria-hidden="true"><path
              d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></span></span></span>
              <div class="ant-tabs-nav-wrap">
                <div class="ant-tabs-nav-scroll">
                  <div class="ant-tabs-nav ant-tabs-nav-animated">
                    <div>
                      <div role="tab" aria-disabled="false" aria-selected="true"
                           routerLink="/app/anasayfa/yazilar" routerLinkActive #arla="routerLinkActive"
                           [ngClass]="{'ant-tabs-tab-active' : arla.isActive }"
                           class="ant-tabs-tab">Yazılar ({{profileSummary.activePosts}})
                      </div>
                      <div role="tab" aria-disabled="false" aria-selected="false"
                           routerLink="/app/anasayfa/onay-bekleyenler" routerLinkActive #irla="routerLinkActive"
                           [ngClass]="{'ant-tabs-tab-active' : irla.isActive }"
                           class=" ant-tabs-tab">Onay Bekleyenler ({{profileSummary.approvalPosts}})
                      </div>
                      <div role="tab" aria-disabled="false" aria-selected="false"
                           routerLink="/app/anasayfa/taslaklar"
                           routerLinkActive #drla="routerLinkActive"
                           [ngClass]="{'ant-tabs-tab-active' : drla.isActive }"
                           class=" ant-tabs-tab">Taslaklar ({{profileSummary.draftPosts}})
                      </div>
                      <div role="tab" aria-disabled="false"
                           routerLink="/app/anasayfa/ayarlar/profil"
                           routerLinkActive #srla="routerLinkActive"
                           [ngClass]="{'ant-tabs-tab-active' : srla.isActive }"
                           aria-selected="false" class=" ant-tabs-tab">Ayarlar
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="ant-tabs-content ant-tabs-content-no-animated ant-tabs-top-content">
      <div class="container">
        <div class="row">
          <div class="post-content"
               [style.width.%]="srla.isActive ? 100: 'auto'"
               [ngClass]="{'col-md-9' : irla.isActive || arla.isActive || drla.isActive }">
            <router-outlet></router-outlet>
          </div>
          <div class="not-setting sidebar col-md-3" *ngIf="irla.isActive || arla.isActive || drla.isActive">
            <app-sidebar></app-sidebar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
