import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {


  constructor(private http: HttpClient, @Inject('environment.apiURL') private apiURL: string) {
  }

  upload(file: File): Observable<IRawImage> {
    const formData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return <any> this.http.post(this.apiURL + '/image/upload', formData, {headers});
  }

}
