<div class="single-content-section-embed" [ngClass]="{'edit-mode':editMode}">

  <ul *ngIf="editMode" class="settings">
    <li>
      <a (click)="removeSection()" class="btn btn-danger">
        <i class=" fa fa-trash text-white"></i>
      </a>
    </li>

    <li>
      <a (click)="moveUp()" class="btn btn-info">
        <i class=" fa fa-arrow-up text-white"></i>
      </a>
    </li>

    <li>
      <a (click)="moveDown()" class="btn btn-info">
        <i class=" fa fa-arrow-down text-white"></i>
      </a>
    </li>
  </ul>

  <div class="text-center" *ngIf="!editMode">

    <iframe *ngIf="youtubeEmbedUrl" type="text/html" width="640" height="360"
            [src]="youtubeEmbedUrl"
            frameborder="0"></iframe>

    <app-embed-tweet *ngIf="tweetId" [tweetId]="tweetId"></app-embed-tweet>

    <iframe *ngIf="instagramEmbedUrl" [src]="instagramEmbedUrl" width="450" height="610"
            frameborder="0" scrolling="no" allowtransparency="true" style="
    border: 1px solid lightgray;"></iframe>

  </div>

  <div *ngIf="editMode">
    <div class="single-content-section-text-area">

      <div class="social-media-embed mt-md-3 ">

        <iframe *ngIf="youtubeEmbedUrl" id="ytplayer" type="text/html" width="460" height="360"
                [src]="youtubeEmbedUrl"
                frameborder="0"></iframe>


        <iframe *ngIf="instagramEmbedUrl" [src]="instagramEmbedUrl" width="450" height="610"
                frameborder="0" scrolling="no" allowtransparency="true" style="
    border: 1px solid lightgray;"></iframe>

        <app-embed-tweet *ngIf="tweetId" [tweetId]="tweetId"></app-embed-tweet>

        <p *ngIf="!added" class="text-center"><strong>YouTube, Twitter</strong> veya
          <strong>Instagram </strong>sayfasının URL'sini girin.</p>


        <div class="form-group"><input *ngIf="!added" [(ngModel)]="section.embed.link" type="name"
                                       class="form-control embed-input"
                                       placeholder="http://pozy.org/url-to-embed">
        </div>
        <button *ngIf="!added" (click)="saveEmbed()" type="button" class="social-media-embed-button">Kodu Yerleştir
        </button>

        <button *ngIf="added" (click)="removeEmbed()" type="button" class="social-media-embed-button clear">
          Yerleştirmeyi
          Sil
        </button>


      </div>
    </div>

  </div>
</div>
