export enum PostStatus {

  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  FILTERED = 'FILTERED',
  DELETED = 'DELETED',
  DUPLICATE = 'DUPLICATE',
  WAITING_APPROVAL = 'WAITING_APPROVAL'

}

export enum PostSectionType {
  SINGLE_PHOTO = 'SINGLE_PHOTO',
  PHOTO_GALLERY = 'PHOTO_GALLERY',
  SIMPLE_TEXT = 'SIMPLE_TEXT',
  EMBED = 'EMBED',
}

export enum PhotoPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM'
}
