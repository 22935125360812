import {Component} from '@angular/core';
import {UserService} from './_services/user.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(userService: UserService, titleService: Title) {
    userService.me().subscribe((x) => {
      titleService.setTitle(x.name);
    });
  }
}
