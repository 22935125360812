import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPhotoGallerySection, IUserPostSection} from '../../../../@types/post';
import {UploadService} from '../../../../_services/upload.service';
import {forkJoin} from 'rxjs';
import * as $ from 'jquery';
import autosize from 'autosize';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {

  @Input() editMode: boolean;

  @Input() section: IUserPostSection;

  @Output() remove = new EventEmitter<IUserPostSection>();

  @Output() up = new EventEmitter<IUserPostSection>();

  @Output() down = new EventEmitter<IUserPostSection>();

  photoGallery: IPhotoGallerySection;

  openOverlay: boolean;

  constructor(private us: UploadService) {
    this.openOverlay = true;
    console.log(this);
  }

  ngOnInit(): void {
    this.photoGallery = this.section.photoGallery;
  }

  moveUp() {
    this.up.emit(this.section);
  }

  moveDown() {
    this.down.emit(this.section);
  }

  removeSection() {
    this.remove.emit(this.section);
  }

  removeImage(removeImage: IRawImage) {
    this.photoGallery.imageList.splice(this.photoGallery.imageList.indexOf(removeImage), 1);
  }

  oninput($event) {
    autosize($($event.target));
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    console.log(files);
    if (this.section.photoGallery.imageList.length === 6) {
      return;
    }
    const allowedTotal = 6;
    const uploadSize = files.length;
    const currentSize = this.photoGallery.imageList.length;

    if (currentSize + uploadSize > allowedTotal) {
      return;
    }

    if (files) {
      const arr = [];

      for (let i = 0; i < 6; i++) {
        if (files[i]) {
          arr.push(this.us.upload(files[i]));
        }
      }

      console.log(arr);

      this.section.uploadProgress = true;
      this.section.uploadValue = 5;

      const interval = setInterval(() => {

        if (this.section.uploadValue < 90) {
          this.section.uploadValue = this.section.uploadValue + 5;
        }

      }, 750);

      forkJoin(arr).subscribe((rs) => {
        clearInterval(interval);
        this.section.uploadValue = 100;
        setTimeout(() => {
          this.section.uploadProgress = false;
          setTimeout(() => {
            this.section.photoGallery.imageList = this.section.photoGallery.imageList.concat(<any> rs);
          }, 100);
        }, 100);
      });
    }
  }

}
