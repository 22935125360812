<div *ngFor="let post of posts" class="post-content-box">
  <div class="row">
    <div class="post-content-box-thumbnail col-4"><a href="/app/content/{{post.postId}}"><img src="{{post.imageUrl}}"
                                                                                              alt="">

      <div class="post-content-box-thumbnail-overlay">
        <div class="post-content-box-thumbnail-overlay-description"><i><img
          src="https://pozy.org/assets/images/hourglass.svg"
          alt=""></i><span>Onay  Bekliyor</span></div>
      </div>
    </a></div>
    <div class="post-content-box-detail col-8">
      <div class="row">
        <div class="col-12 post-content-box-description">
          <ul class="post-content-box-description-list">
            <li class="post-content-box-description-list-category"><span
              class="badge badge-secondary">{{post.categoryName}}</span>
            </li>
            <li class="post-content-box-description-list-author"><a
              href="/app/icerik/{{post.postId}}">{{post.sourceName}}</a></li>
          </ul>
        </div>
        <div class="clearfix"></div>
        <div class="col-12 post-content-box-description"><h1 class="post-content-box-description-title"><a
          href="/app/icerik/{{post.postId}}" [innerHTML]="post.title"></a></h1>
          <p [innerHTML]="post.summary"></p></div>
      </div>
    </div>
  </div>
</div>

