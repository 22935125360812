import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserService} from '../../_services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

  public profileSummary: IUserProfileSummary = {};
  public simpleUser: ISimpleUser = {};

  constructor(private us: UserService) {
  }

  ngOnInit() {
    this.us.profileSummary(true)
      .subscribe((summary) => {
        this.profileSummary = summary;
        console.log(summary);
      });

    this.us.me(false).subscribe((userSummay) => {
      this.simpleUser = userSummay;
      console.log(userSummay);
    });
  }
}
