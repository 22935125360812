import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DefaultLayoutComponent} from './modules/layout-module/default-layout-component/default-layout.component';
import {EmptyLayoutComponent} from './modules/layout-module/empty-layout/empty-layout.component';
import {AuthGuard} from './_guards';

const routes: Routes = [
  {
    path: 'app',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/app/anasayfa/yazilar',
        pathMatch: 'full'
      },
      {
        component: DefaultLayoutComponent,
        path: 'anasayfa',
        loadChildren: './pages/home/home.module#HomeModule',
      },
      {
        component: EmptyLayoutComponent,
        path: 'icerik',
        loadChildren: './pages/write-content/write-content.module#WriteContentModule'
      }
    ]
  },
  {
    path: '',
    redirectTo: '/app/anasayfa/yazilar',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/app/anasayfa/yazilar',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
