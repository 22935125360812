import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IUserPostSection} from '../../../../@types/post';
import * as $ from 'jquery';
import autosize from 'autosize';

@Component({
  selector: 'app-simple-text',
  templateUrl: './simple-text.component.html',
  styleUrls: ['./simple-text.component.scss']
})
export class SimpleTextComponent implements OnInit {

  @Input() editMode: boolean;

  @Input() section: IUserPostSection;

  @Output() remove = new EventEmitter<IUserPostSection>();

  @Output() up = new EventEmitter<IUserPostSection>();

  @Output() down = new EventEmitter<IUserPostSection>();

  constructor() {
  }

  ngOnInit(): void {
  }

  oninput($event) {
    autosize($($event.target));
  }

  moveUp() {
    this.up.emit(this.section);
  }

  moveDown() {
    this.down.emit(this.section);
  }

  removeSection() {
    this.remove.emit(this.section);
  }

}
