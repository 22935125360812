import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private meSubject = new ReplaySubject<ISimpleUser>(1);
  private summarySubject = new ReplaySubject<IUserProfileSummary>(1);

  constructor(private http: HttpClient, @Inject('environment.apiURL') private apiURL: string) {
  }

  me(invoke?: boolean): Observable<ISimpleUser> {
    if (invoke) {
      this.http.get(this.apiURL + '/user/me').subscribe((response: any) => {
        this.meSubject.next(response);
      });
    }
    return this.meSubject.asObservable();
  }
  profileSummary(invoke?: boolean): Observable<IUserProfileSummary> {
    if (invoke) {
      this.http.get(this.apiURL + '/profile/summary').subscribe((response: any) => {
        this.summarySubject.next(response);
      });
    }
    return this.summarySubject.asObservable();
  }

  revokeMe() {
    this.http.get(this.apiURL + '/user/me').subscribe((response: any) => {
      this.meSubject.next(response);
    });
  }

  revokeProfileSummary() {
    this.http.get(this.apiURL + '/profile/summary').subscribe((response: any) => {
      this.summarySubject.next(response);
    });
  }

}
