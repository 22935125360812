import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient, @Inject('environment.apiURL') private apiURL: string) {
  }

  categoryList(): Observable<ICategory[]> {
    return <any> this.http.get(this.apiURL + '/category');
  }

}
