import {Component, OnInit} from '@angular/core';
import {SettingService} from '../../../../_services/setting.service';
import {UploadService} from '../../../../_services/upload.service';
import {UserService} from '../../../../_services/user.service';
import {ToastService} from '../../../../_services/toast.service';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
})
export class ProfileSettingsComponent implements OnInit {

  public profileSettings: IProfileSettings;
  public settings: ISourceSettings;
  public imageUploading: boolean;

  constructor(private ss: SettingService, private us: UploadService, private userService: UserService, private ts: ToastService) {
    console.log(this);
  }

  ngOnInit(): void {
    this.imageUploading = false;
    this.ss.profileSettings().subscribe((resp) => {
      this.profileSettings = resp;
    });
    this.ss.sourceSettings().subscribe((resp) => {
      this.settings = resp;
      console.log(resp);
    });
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const file: File = files[0];

    if (files && file) {
      this.imageUploading = true;
      this.us.upload(file).subscribe((resp) => {
        this.profileSettings.avatarUrl = resp.url;
        this.settings.sourceUrl = resp.url;
        this.imageUploading = false;
      });
    }
  }

  save() {
    this.ss.saveProfileSettings(this.profileSettings).subscribe(() => {
      console.log('savedd');
      this.ts.showSuccess('Ayarlar Kaydedildi.');
      this.userService.revokeMe();
    });

    this.ss.saveSourceSettings(this.settings).subscribe(() => {
      console.log('savedd');
      this.userService.revokeProfileSummary();
    });
  }

}
