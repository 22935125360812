import {Component, Input, OnInit} from '@angular/core';
import {IUserPostSection} from '../../../../../@types/post';
import * as $ from 'jquery';
import autosize from 'autosize';

@Component({
  selector: 'app-single-photo-text-area',
  templateUrl: './single-photo-text-area.component.html',
  styleUrls: ['./single-photo-text-area.component.scss']
})
export class SinglePhotoTextAreaComponent implements OnInit {

  @Input() editMode: boolean;

  @Input() section: IUserPostSection;

  constructor() {
  }

  ngOnInit(): void {
  }

  oninput($event) {
    autosize($($event.target));
  }
}
