import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmbedTweetComponent} from './embed-tweet/embed-tweet.component';


@NgModule({
  declarations: [EmbedTweetComponent],
  exports: [
    EmbedTweetComponent
  ],
  imports: [
    CommonModule
  ]
})
export class TwitterModule {
}
