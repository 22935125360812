<div class="profile-settings" *ngIf="profileSettings && settings">
  <div class="profile-settings-image col-md-12">
    <div class="row">
      <div class="profile-settings-image-preview col-md-2">
        <div *ngIf="imageUploading" class="spinner-border absolute-center text-primary" role="status">

        </div>
        <img *ngIf="!imageUploading" [src]="settings.sourceUrl" class="img-responsive"
             alt="">
      </div>
      <div class="profile-settings-image-description col-md-3"><h2>Profil Fotoğrafı</h2>
        <p>Profil fotoğrafı minimum genişliği 500px olup, 3 MB’ın altında olmalıdır.</p><label
          class="btn button">Dosya Seçin <input type="file" (change)="handleFileSelect($event)" hidden=""></label></div>
    </div>
  </div>
  <div class="profile-settings-form col-md-12">

    <div class="form-group "><label>İsim Soyisim</label><label class="error-message"></label><input
      name="name" class="form-control" [(ngModel)]="profileSettings.displayName"></div>

    <div class="form-group "><label>E Posta</label><label class="error-message"></label><input name="email"
                                                                                               class="form-control"
                                                                                               [(ngModel)]="profileSettings.email"><span></span>
    </div>
    <div class="form-group "><label>Biyografi</label><label class="error-message"></label><textarea
      name="biography" class="form-control" rows="4" [(ngModel)]="profileSettings.biography"></textarea></div>


    <div class="form-group "><label>Kayna Goruntulenme İsmi</label><label class="error-message"></label><input
      name="name" class="form-control" [(ngModel)]="settings.sourceName"></div>

    <div class="form-group "><label>Kullanici Ismi</label><label class="error-message"></label><input
      name="username" [disabled]="true" class="form-control" [(ngModel)]="settings.sourceRoute"><span>https://www.pozy.org/kaynak</span>
    </div>

    <div class="form-group "><label>Kişisel Websitesi</label><label class="error-message"></label><input
      name="website" class="form-control" [(ngModel)]="settings.website"></div>

    <label for="twitter-url">Twitter Kullanıcı Adı</label>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="twitter-addon">https://twitter.com/</span>
      </div>
      <input type="text" class="form-control" id="twitter-url" [(ngModel)]="settings.twitter"
             aria-describedby="twitter-addon">
    </div>

    <label for="facebook-url">Facebook Kullanıcı Adı</label>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="facebook-addon">https://www.facebook.com/</span>
      </div>
      <input type="text" class="form-control" id="facebook-url" [(ngModel)]="settings.facebook"
             aria-describedby="facebook-addon">
    </div>

    <label for="instagram-url">Instagram Kullanıcı Adı</label>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="instagram-addon">https://www.instagram.com/</span>
      </div>
      <input type="text" class="form-control" id="instagram-url" [(ngModel)]="settings.instagram"
             aria-describedby="instagram-addon">
    </div>

    <label for="linkedin-url">LinkedIn Kullanıcı Adı</label>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="linkedin-addon">https://www.linkedin.com/in/</span>
      </div>
      <input type="text" class="form-control" id="linkedin-url" [(ngModel)]="settings.linkedin"
             aria-describedby="linkedin-addon">
    </div>

    <button type="button" (click)="save()" class="col-md-12 col-xs-12 profile-settings-form-save btn btn-primary">
      KAYDET
    </button>

  </div>
</div>
