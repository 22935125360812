import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './home.component';
import {ArticlesComponent} from './articles/articles.component';
import {WaitingApprovalComponent} from './waiting-approval/waiting-approval.component';
import {DraftsComponent} from './drafts/drafts.component';
import {SettingsComponent} from './settings/settings.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import { ProfileSettingsComponent } from './settings/profile-settings/profile-settings.component';
import { SourceSettingsComponent } from './settings/source-settings/source-settings.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HomeRoutingModule
  ],
  declarations: [
    HomeComponent,
    ArticlesComponent,
    WaitingApprovalComponent,
    DraftsComponent,
    SettingsComponent,
    SidebarComponent,
    ProfileSettingsComponent,
    SourceSettingsComponent
  ]
})
export class HomeModule {
}
