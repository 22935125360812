<footer id="footer">
  <div class="container footer-container">
    <div class="row">
      <div class="col-md-6">
        <ul>
          <li><p class="title">Nedir?</p></li>
        </ul>
        <p class="what-is-pozy">pozy.org, mutlu haber süzgeci ile pozitif içerikleri yakalayan sosyal girişimdir.</p><a
        href="https://itunes.apple.com/tr/app/pozy-mutlu-haber-süzgeci/id1323808635" class="app_download"><img
        height="40px" src="https://pozy.org/assets/images/appstore@2x.png" alt=""></a><a
        href="https://play.google.com/store/apps/details?id=org.pozy" class="app_download"><img height="40px"
                                                                                                src="https://pozy.org/assets/images/google-play@2x.png"
                                                                                                alt=""></a>
        <ul class="socials">
          <li><a href="https://www.facebook.com/pozyorg" target="_blank"><i class="fa fa-facebook font-26"
                                                                            aria-hidden="true"></i></a></li>
          <li><a href="https://www.twitter.com/pozyorg" target="_blank"><i class="fa fa-twitter font-26"
                                                                           aria-hidden="true"></i></a></li>
          <li><a href="https://www.instagram.com/pozyorg" target="_blank"><i class="fa fa-instagram font-26"
                                                                             aria-hidden="true"></i></a></li>
          <li><a href="https://www.youtube.com/channel/UC__IHkxenC37K_Yz9zkM3wQ" target="_blank"><i
            class="fa fa-youtube font-26" aria-hidden="true"></i></a></li>
          <li><a href="https://www.linkedin.com/company/pozyorg" target="_blank"><i class="fa fa-linkedin font-26"
                                                                                    aria-hidden="true"></i></a></li>
          <li><a href="https://open.spotify.com/user/ificsdi0hfa7ctl53o53cowhl?si=B7BCW7DCQPWq36bSPF_kwg"
                 target="_blank"><i class="fa fa-spotify font-26" aria-hidden="true"></i></a></li>
        </ul>
      </div>
      <div class="col-md-3 col-md-offset-1 col-sm-4 col-xs-6 footer-links">
        <ul>
          <li><p class="title">Hayale Ortak Ol</p></li>
          <li><a href="/pozy-elcisi-ol">Pozy Elçisi Ol</a></li>
          <li><a href="/bulten">Mutlu Haber Bülteni</a></li>
          <li><a href="/pozy-haber-ilkeleri-ve-kurallari">Pozy Haber İlkeleri ve Kuralları</a></li>
        </ul>
      </div>
      <div class="col-md-2 col-sm-4 col-xs-6 footer-links">
        <ul>
          <li><p class="title">Sosyal Girişim</p></li>
          <li><a href="/neler-yaptik">Neler Yaptık?</a></li>
          <li><a href="/iletisim">İletişim</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container copyright-container">
    <div class="row">
      <div class="col-md-6 text-left">
        <div class="more-info"><a href="/kullanim-kosullari">Kullanım Koşulları</a> / <a href="/gizlilik-politikasi">
          Kişisel Veriler Politikası</a><br>© 2020 pozy.org / mutlu haber süzgeci - <a href="/gecmis">v2.1 neşe</a>
        </div>
      </div>
      <div class="col-md-6 text-right"><br>
        <div class="made-by">sunucu desteği &nbsp;<img height="45px"
                                                       src="https://pozy.org/assets/images/Amazon_Web_Services_Logo.svg"
                                                       alt=""></div>
      </div>
    </div>
  </div>
</footer>
