<div *ngIf="post && profileSummary">
  <div class="single edit-mod">
    <div class="single-cover"
         [ngStyle]="{'background-image': 'url(' + post.imageUrl + ')'}">
      <nav>
        <div class="container-fluid">
          <div class="row">
            <div class="nav-menu nav-back col-md-2 col-xs-2"><span class="back-button"><a href="/profilim"><img
              src="http://99.80.241.28/static/media/back.97fc688d.svg" alt=""></a></span></div>
            <div class="nav-menu col-md-10 col-xs-10">
              <ul class="pull-right">
                <div class="hidden-xs hidden-sm">

                  <li *ngIf="post.status !== 'WAITING_APPROVAL' && post.status!== 'PUBLISHED'"><span
                    class="btn btn-info"
                    (click)="toggleMode()"
                    *ngIf="editMode">ÖNİZLE</span>
                  </li>

                  <li *ngIf="post.status !== 'WAITING_APPROVAL' && post.status!== 'PUBLISHED'"><span class="btn btn-primary"
                            *ngIf="!editMode"
                            (click)="toggleMode()"
                  >DÜZENLE</span></li>
                </div>
                <li *ngIf="post.status !== 'WAITING_APPROVAL' && post.status!== 'PUBLISHED'"
                    class="nav-menu-button"><span class="btn btn-primary "
                                                  (click)="saveAllChanges()"
                                                  *ngIf="editMode">KAYDET</span></li>
                <li *ngIf="post.status ==='DRAFT' && post.id" (click)="sendApprove()" class="nav-menu-button"><span
                  class="btn btn-success"
                  *ngIf="editMode">YAYINLA</span></li>

                <li *ngIf="!post.id" (click)="saveAndApprove()" class="nav-menu-button"><span
                  class="btn btn-secondary"
                  *ngIf="editMode">KAYDET VE ONAYA GÖNDER</span></li>


              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div class="row">
        <div class="single-cover-content-description col-md-8 col-md-offset-2 col-xs-12"
             style="position: absolute;z-index: 2;top: 30%;left: 0;bottom: 0;right: 0; margin: auto">

          <div *ngIf="editMode" class="single-cover-image-upload">
            <div class="hidden-xs hidden-sm">
              <a type="button" class="inputWrapper">
                <input class="fileInput" accept="image/x-png, image/png, image/jpg, image/jpeg, image/gif"
                       type="file" autocomplete="off" (change)="handleFileSelect($event)">Bir kapak
                fotoğrafı ekleyin, yüklemek için tıklayın
              </a>
            </div>
          </div>

          <textarea class="ant-input single-cover-content-description-title" placeholder="İÇERİK ADI OLUŞTUR"
                    *ngIf="editMode"
                    (input)="oninput($event)"
                    maxlength="120" [(ngModel)]="post.title"></textarea>

          <h1 *ngIf="!editMode" class="single-content-title text-white break-word">
            {{post.title}}
          </h1>


          <textarea class="ant-input single-cover-content-description-title single-cover-content-description-summary"
                    placeholder="Hikayeyi yazmak için buradan başlayabilirsin! :)"
                    *ngIf="editMode"
                    (input)="oninput($event)"
                    maxlength="1000" [(ngModel)]="post.summary"></textarea>

          <div *ngIf="!editMode" class="simple-single-photo-section-summary text-white break-word">
            {{post.summary}}
          </div>


        </div>
      </div>

      <div class="post-top-section">


        <!--        <fate-input *ngIf="editMode" customClass="title-header" placeholder="İÇERİK ADI OLUŞTUR"-->
        <!--                    [(ngModel)]="post.title"></fate-input>-->

        <!--        <div *ngIf="!editMode" class="text-title" [innerHTML]="post.title"></div>-->

        <!--        <fate-input *ngIf="editMode" customClass="summary-header"-->
        <!--                    placeholder="Hikayeyi yazmak için buradan başlayabilirsin! :)"-->
        <!--                    [(ngModel)]="post.summary"></fate-input>-->

        <!--        <div *ngIf="!editMode" class="text-summary" [innerHTML]="post.summary"></div>-->

      </div>


      <div class="single-cover-content-author-description"><img
        [src]="profileSummary.sourceLogo"
        class="single-cover-content-author-description-image" alt="">
        <h3 class="single-cover-content-author-description-name mt-2 mb-2">
          <span>{{profileSummary.sourceDisplayName}}</span></h3>

        <ul *ngIf="!editMode" class="single-cover-content-author-description-tag">
          <li><a class="btn btn-info btn-sm text-white mb-2 mt-2">{{selectedCategory || 'Kategori Secilmedi'}}</a></li>
        </ul>

        <div *ngIf="editMode" ngbDropdown class="d-inline-block">
          <button class="btn btn-primary" id="dropdownBasic1"
                  ngbDropdownToggle>{{selectedCategory || 'Kategori Sec'}}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button (click)="selectCategory(category)" ngbDropdownItem
                    *ngFor="let category of categories">{{category.name}}</button>
          </div>
        </div>
      </div>


      <div class="backdrop"></div>
    </div>
    <div class="single-content">

      <div class="section-index" *ngFor="let section of post.sectionList">

        <div class="single-content-section">
          <div class="container">
            <div class="row">
              <div class="col-md-12">


                <div *ngIf="section.type==='EMBED'">
                  <app-embed [section]="section" [editMode]="editMode" (down)="moveDown($event)"
                             (up)="moveUp($event)" (remove)="removeSection($event)"></app-embed>
                </div>

                <div *ngIf="section.type==='SINGLE_PHOTO'">
                  <app-single-photo [section]="section" [editMode]="editMode" (down)="moveDown($event)"
                                    (up)="moveUp($event)" (remove)="removeSection($event)"></app-single-photo>
                </div>

                <div *ngIf="section.type==='SIMPLE_TEXT'">
                  <app-simple-text [section]="section" [editMode]="editMode" (down)="moveDown($event)"
                                   (up)="moveUp($event)" (remove)="removeSection($event)"></app-simple-text>
                </div>

                <div *ngIf="section.type==='PHOTO_GALLERY'">
                  <app-photo-gallery [section]="section" [editMode]="editMode" (down)="moveDown($event)"
                                     (up)="moveUp($event)" (remove)="removeSection($event)"></app-photo-gallery>
                </div>


              </div>
            </div>

          </div>
        </div>
      </div>

      <div *ngIf="editMode" class="edit-mod-sticky-nav hidden-xs hidden-sm">
        <ul class="edit-mod-sticky-nav-list">

          <li (click)="addSection('SINGLE_PHOTO')" class="inputWrapper edit-mod-sticky-nav-list-item">

            <input class="fileInput" accept="image/x-png, image/png, image/jpg, image/jpeg, image/gif"
                   type="file" autocomplete="off" (change)="handleSingleSelect($event)">
            <i><img
              src="/assets/images/align-left-white.7f049386.svg"
              alt=""></i><span>TEK FOTOĞRAF VE YAZI</span></li>

          <li (click)="addSection('PHOTO_GALLERY')" class="inputWrapper edit-mod-sticky-nav-list-item">
            <input class="fileInput"
                   (change)="handleMultiSelect($event)"
                   accept="image/x-png, image/png, image/jpg, image/jpeg, image/gif"
                   multiple=""
                   type="file" autocomplete="off">

            <i><img
              src="/assets/images/align-top-white.a421384c.svg"
              alt=""></i><span>FOTOĞRAF GALERİSİ &amp; YAZI</span></li>

          <li (click)="addSection('SIMPLE_TEXT')" class="edit-mod-sticky-nav-list-item"><i><img
            src="/assets/images/text.f3800dd7.svg"
            alt=""></i><span>SADECE YAZI</span>
          </li>

          <li (click)="addSection('EMBED')" class="edit-mod-sticky-nav-list-item"><i><img
            src="/assets/images/embed.92f6d54a.svg"
            alt=""></i><span>EMBED</span>
          </li>

        </ul>
      </div>


    </div>
  </div>
</div>
