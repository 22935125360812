<div class="container settings-container">
  <div class="row">
    <div class="sidebar col-md-3 col-md-pull-9 col-xs-12">
      <div class="sidebar-widget sidebar-widget-settings">
        <ul class="sidebar-widget-settings-menu">
          <li routerLink="/app/anasayfa/ayarlar/profil" [routerLinkActive]="['active']">
            <a>Profil Ayarları
              <i><img src="https://pozy.org/assets/images/next.svg"></i>
            </a>
          </li>

<!--          <li routerLink="/app/home/settings/source" [routerLinkActive]="['active']">-->
<!--            <a>Kanal Ayarları-->
<!--              <i><img src="https://pozy.org/assets/images/next.svg"></i>-->
<!--            </a>-->
<!--          </li>-->
        </ul>
        <!--        <div class="sidebar-widget-settings-bottom"><a href="#" class="sidebar-widget-settings-button"><i><img-->
        <!--          src="http://99.80.241.28/assets/images/delete.svg" alt=""></i>Hesabını devre dışı bırak</a></div>-->
      </div>
    </div>

    <div class="col-md-9 col-md-push-3 col-xs-12">

      <router-outlet></router-outlet>
    </div>

  </div>
</div>

