import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HoverClassDirective} from './hover-class.directive';

// import '@angular/localize/init';

@NgModule({
  declarations: [HoverClassDirective],
  exports: [
    HoverClassDirective
  ],
  imports: [
    CommonModule,
    // NgbModule
  ]
})
export class TemplateModule {
}
