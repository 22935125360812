import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {HomeComponent} from './home.component';
import {ArticlesComponent} from './articles/articles.component';
import {DraftsComponent} from './drafts/drafts.component';
import {WaitingApprovalComponent} from './waiting-approval/waiting-approval.component';
import {SettingsComponent} from './settings/settings.component';
import {FormsModule} from '@angular/forms';
import {ProfileSettingsComponent} from './settings/profile-settings/profile-settings.component';

const homeRoutes: Routes = [
  {
    path: '',
    redirectTo: 'yazilar',
    pathMatch: 'full'
  },
  {
    path: '', component: HomeComponent,
    children: [
      {
        path: 'yazilar', component: ArticlesComponent
      },
      {
        path: 'taslaklar', component: DraftsComponent
      },
      {
        path: 'onay-bekleyenler', component: WaitingApprovalComponent
      },
      {
        path: 'ayarlar', component: SettingsComponent,
        children: [{
          path: 'profil', component: ProfileSettingsComponent
        }]
      }
    ]
  }
];

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forChild(homeRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class HomeRoutingModule {
}
