import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {WriteContentComponent} from './write-content.component';
import {WriteContentRoutingModule} from './write-content-routing.module';
import {SectionsModule} from './sections/sections.module';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WriteContentRoutingModule,
    SectionsModule,
    NgbDropdownModule
  ],
  declarations: [
    WriteContentComponent
  ]
})
export class WriteContentModule {

}
