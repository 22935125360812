import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IUserPostSection} from '../../../../@types/post';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.scss']
})
export class EmbedComponent implements OnInit, OnChanges {
  private twitter: any;

  youtubeRegexp = new RegExp('^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+');
  twitterRegexp = new RegExp('http(?:s)?:\/\/(?:www)?twitter\.com\/([a-zA-Z0-9_]+)\/status');
  instagramRegexp = new RegExp('(https?:\/\/(?:www\.)?instagram\.com\/p\/([^/?#&]+)).*');

  @Input() editMode: boolean;

  @Input() section: IUserPostSection;

  @Output() remove = new EventEmitter<IUserPostSection>();

  @Output() up = new EventEmitter<IUserPostSection>();

  @Output() down = new EventEmitter<IUserPostSection>();

  tweetId = null;
  youtubeEmbedUrl = null;
  added = false;
  instagramEmbedUrl = null;

  constructor(private router: Router, private sanitizer: DomSanitizer) {
    console.log(this);

  }

  saveEmbed() {
    // console.log(this.isValid());
    delete this.tweetId;
    delete this.youtubeEmbedUrl;
    delete this.instagramEmbedUrl;
    this.added = false;
    this.initialize();
  }

  initialize() {
    if (this.section.embed.link) {
      if (this.youtubeRegexp.test(this.section.embed.link)) {
        this.section.embed.source = 'YOUTUBE';
        if (this.section.embed.link.indexOf('?v=') >= 0) {
          this.section.embed.link = 'https://www.youtube.com/embed/' + this.section.embed.link.split('?v=')[1];
        }
        this.youtubeEmbedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.section.embed.link);
        this.added = true;
      } else if (this.twitterRegexp.test(this.section.embed.link)) {
        this.section.embed.source = 'TWITTER';
        this.tweetId = this.section.embed.link.split('status/')[1];
        this.added = true;
      } else {
        this.section.embed.source = 'INSTAGRAM';
        this.section.embed.link = this.section.embed.link + '/embed';
        if (this.section.embed.link.indexOf('//embed') >= 0) {
          this.section.embed.link = this.section.embed.link.replace('//embed', '/embed');
        }
        if (this.section.embed.link.indexOf('/embed/embed') >= 0) {
          this.section.embed.link = this.section.embed.link.replace('/embed/embed', '/embed');
        }
        this.instagramEmbedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.section.embed.link);
        this.added = true;
      }
    }
  }

  removeEmbed() {
    delete this.tweetId;
    delete this.youtubeEmbedUrl;
    delete this.instagramEmbedUrl;
    this.added = false;
    delete this.section.embed.source;
    delete this.section.embed.link;
  }

  isValid() {
    return this.youtubeRegexp.test(this.section.embed.link) || this.twitterRegexp.test(this.section.embed.link)
      || this.instagramRegexp.test(this.section.embed.link);
  }


  ngOnInit(): void {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editMode) {
      setTimeout(() => {
        const el: any = document.getElementsByTagName('twitter-widget')[0];
        el.style.margin = 'auto';
      }, 150);
    }
  }

  moveUp() {
    this.up.emit(this.section);
  }

  moveDown() {
    this.down.emit(this.section);
  }

  removeSection() {
    this.remove.emit(this.section);
  }

}
