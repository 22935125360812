import {Component, OnInit} from '@angular/core';
import {SettingService} from '../../../../_services/setting.service';
import {UploadService} from '../../../../_services/upload.service';
import {UserService} from '../../../../_services/user.service';

@Component({
  selector: 'app-source-settings',
  templateUrl: './source-settings.component.html',
})
export class SourceSettingsComponent implements OnInit {

  public settings: ISourceSettings;

  constructor(private ss: SettingService, private us: UploadService, private userService: UserService) {
  }

  ngOnInit(): void {
    this.ss.sourceSettings().subscribe((resp) => {
      this.settings = resp;
      console.log(resp);
    });
  }

  handleFileSelect(evt) {
    const files = evt.target.files;
    const file: File = files[0];

    if (files && file) {
      this.us.upload(file).subscribe((resp) => {
        this.settings.sourceUrl = resp.url;
      });
    }
  }

  save() {
    this.ss.saveSourceSettings(this.settings).subscribe(() => {
      console.log('savedd');
      this.userService.revokeProfileSummary();
    });
  }
}
