<header class=" profile-header">

  <nav class="navbar navbar-expand-lg justify-content-between align-items-center  container">
    <a class="navbar-brand" href="https://www.pozy.org">
      <img style="height: 25px" src="https://pozy.org/assets/images/pozy-logo.svg"/>

    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>


    <button class="btn btn-primary" routerLink="/app/icerik"><i class="fa fa-plus-circle pr-1"></i>Icerik
      Gir
    </button>

  </nav>
  <!--  <div class="container border-seperate">-->
  <!--    <div class="row">-->
  <!--      <div class="col-md-4 filter-left">-->
  <!--        <div class="col-md-2 header-filter-icons"><img src="http://99.80.241.28/assets/images/filter.svg" alt=""></div>-->
  <!--      </div>-->
  <!--      <div class="col-md-4 text-center"><a href="/" class="logo"><img height="40px" src="http://99.80.241.28/assets/images/pozy-logo.svg"-->
  <!--                                                                      alt="Pozy"></a></div>-->
  <!--      <div class="col-md-4 send-pozy-news">-->
  <!--        <div class="profile-panel header-profile">-->
  <!--          <div class="dropdown btn-group">-->
  <!--            <button id="menu-user" role="button" aria-haspopup="true" aria-expanded="false" type="button"-->
  <!--                    class="menu-others dropdown-toggle btn btn-default">Hesabım <span class="caret"></span></button>-->
  <!--            <ul role="menu" class="dropdown-menu" aria-labelledby="menu-user">-->
  <!--              <li role="presentation" class=""><a href="/profilim" role="menuitem" tabindex="-1">Profilim</a></li>-->
  <!--              <li role="presentation" class=""><a href="/profilim/onay-bekleyen" role="menuitem" tabindex="-1">Onay-->
  <!--                Bekleyen (3)</a></li>-->
  <!--              <li role="presentation" class=""><a href="/profilim/ayarlar" role="menuitem" tabindex="-1">Ayarlar</a>-->
  <!--              </li>-->
  <!--              <li role="presentation" class=""><a href="/iletisim" role="menuitem" tabindex="-1">Geri Bildirim-->
  <!--                Gönder</a></li>-->
  <!--              <li role="presentation" class=""><a role="menuitem" tabindex="-1" href="#">Çıkış Yap</a></li>-->
  <!--            </ul>-->
  <!--          </div>-->
  <!--          <span class="ant-avatar avatar ant-avatar-circle ant-avatar-icon">user</span></div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--  <nav class="fixed-nav">-->
  <!--    <div class="container">-->
  <!--      <div class="row">-->
  <!--        <div class="header-second-logo col-md-2"><a href="/" class="logo"><img height="40px"-->
  <!--                                                                               src="http://99.80.241.28/assets/images/pozy-logo.svg"-->
  <!--                                                                               alt="Pozy"></a></div>-->
  <!--        <div class="header-menu col-md-8">-->
  <!--          <ul>-->
  <!--            <li><a href="/category/5">Yaşam</a></li>-->
  <!--            <li><a href="/category/12">Kültür/Sanat</a></li>-->
  <!--            <li><a href="/category/1">Teknoloji</a></li>-->
  <!--            <li><a href="/category/2">Spor</a></li>-->
  <!--            <li><a href="/category/7">Eğitim</a></li>-->
  <!--            <li><a href="/category/10">Sağlık</a></li>-->
  <!--            <li><a href="/category/18">Bizden Haberler</a></li>-->
  <!--          </ul>-->
  <!--          <div class="dropdown btn-group">-->
  <!--            <button id="menu-others" role="button" aria-haspopup="true" aria-expanded="false" type="button"-->
  <!--                    class="menu-others dropdown-toggle btn btn-default">Diğer <span class="caret"></span></button>-->
  <!--            <ul role="menu" class="dropdown-menu" aria-labelledby="menu-others">-->
  <!--              <li role="presentation" class=""><a href="/category/3" role="menuitem" tabindex="-1">Bilim</a></li>-->
  <!--              <li role="presentation" class=""><a href="/category/4" role="menuitem" tabindex="-1">Ekonomi</a></li>-->
  <!--              <li role="presentation" class=""><a href="/category/16" role="menuitem" tabindex="-1">Çevre</a></li>-->
  <!--              <li role="presentation" class=""><a href="/category/6" role="menuitem" tabindex="-1">Dünya</a></li>-->
  <!--              <li role="presentation" class=""><a href="/category/9" role="menuitem" tabindex="-1">Sinema</a></li>-->
  <!--              <li role="presentation" class=""><a href="/category/11" role="menuitem" tabindex="-1">Seyahat</a></li>-->
  <!--              <li role="presentation" class=""><a href="/category/13" role="menuitem" tabindex="-1">Gündem</a></li>-->
  <!--              <li role="presentation" class=""><a href="/category/14" role="menuitem" tabindex="-1">STK</a></li>-->
  <!--              <li role="presentation" class=""><a href="/category/8" role="menuitem" tabindex="-1">Mutfak</a></li>-->
  <!--            </ul>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="header-profile col-md-2 pull-right col-xs-9">-->
  <!--          <div class="dropdown btn-group">-->
  <!--            <button id="menu-others" role="button" aria-haspopup="true" aria-expanded="false" type="button"-->
  <!--                    class="menu-others-pozy dropdown-toggle btn btn-default">Pozy Hakkında <span class="caret"></span>-->
  <!--            </button>-->
  <!--            <ul role="menu" class="dropdown-menu" aria-labelledby="menu-others">-->
  <!--              <li role="presentation" class=""><a href="/neler-yaptik" role="menuitem" tabindex="-1">Neler Yaptık?</a>-->
  <!--              </li>-->
  <!--              <li role="presentation" class=""><a href="/pozy-elcisi-ol" role="menuitem" tabindex="-1">Pozy Elçisi-->
  <!--                Ol</a></li>-->
  <!--              <li role="presentation" class=""><a href="/iletisim" role="menuitem" tabindex="-1">İletişim</a></li>-->
  <!--            </ul>-->
  <!--          </div>-->
  <!--          <ul></ul>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </nav>-->
  <!--</header>-->
  <!--<header class="header-mobile hidden-md hidden-lg">-->
  <!--  <div class="header-mobile-nav">-->
  <!--    <div class="header-logo col-md-1 col-xs-3"><a href="/"><img src="http://99.80.241.28/assets/images/pozy-logo.svg" alt=""></a></div>-->
  <!--    <div class="header-profile col-md-2 col-xs-9">-->
  <!--      <ul>-->
  <!--        <li class="profile-image">-->
  <!--          <ul class="dropdown-menu pull-right" role="menu">-->
  <!--            <li><a href="#">Action</a></li>-->
  <!--            <li><a href="profile.html">Profilim</a></li>-->
  <!--            <li><a href="#">Something else here</a></li>-->
  <!--            <li><a href="#">Separated link</a></li>-->
  <!--          </ul>-->
  <!--        </li>-->
  <!--        <li class="mobile-menu-button hidden-lg hidden-md">-->
  <!--          <button><img src="http://99.80.241.28/assets/images/mobile-menu-icon.svg" alt=""></button>-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--  <div class="header-mobile-menu">-->
  <!--    <ul>-->
  <!--      <li><a href="/category/5">Yaşam</a></li>-->
  <!--      <li><a href="/category/12">Kültür/Sanat</a></li>-->
  <!--      <li><a href="/category/1">Teknoloji</a></li>-->
  <!--      <li><a href="/category/2">Spor</a></li>-->
  <!--      <li><a href="/category/7">Eğitim</a></li>-->
  <!--      <li><a href="/category/10">Sağlık</a></li>-->
  <!--      <li class="sub-have"><a class="sub-have-link">Diğer<i class="pull-right"><img-->
  <!--        src="/assets/images/dropdown-arrow.svg" alt=""></i></a>-->
  <!--        <ul class="sub-have-nav">-->
  <!--          <li><a href="/category/18">Bizden Haberler</a></li>-->
  <!--          <li><a href="/category/3">Bilim</a></li>-->
  <!--          <li><a href="/category/4">Ekonomi</a></li>-->
  <!--          <li><a href="/category/16">Çevre</a></li>-->
  <!--          <li><a href="/category/6">Dünya</a></li>-->
  <!--          <li><a href="/category/9">Sinema</a></li>-->
  <!--          <li><a href="/category/11">Seyahat</a></li>-->
  <!--          <li><a href="/category/13">Gündem</a></li>-->
  <!--          <li><a href="/category/14">STK</a></li>-->
  <!--          <li><a href="/category/8">Mutfak</a></li>-->
  <!--        </ul>-->
  <!--      </li>-->
  <!--      <li class="sub-have"><a class="sub-have-link">Pozy Hakkında<i class="pull-right"><img-->
  <!--        src="http://99.80.241.28/assets/images/dropdown-arrow.svg" alt=""></i></a>-->
  <!--        <ul class="sub-have-nav">-->
  <!--          <li><a href="/neler-yaptik">Neler Yaptık?</a></li>-->
  <!--          <li><a href="/pozy-elcisi-ol">Pozy Elçisi Ol</a></li>-->
  <!--          <li><a href="/iletisim">İletişim</a></li>-->
  <!--        </ul>-->
  <!--      </li>-->
  <!--    </ul>-->
  <!--  </div>-->
</header>
