import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TwitterService} from '../twitter.service';

@Component({
  selector: 'app-embed-tweet',
  template: '<ng-content *ngIf="isTwitterScriptLoading"></ng-content>',
  styles: ['.twitter-tweet { transform: none !important; }'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedTweetComponent implements OnInit {

  @Input() public tweetId: string;

  public isTwitterScriptLoading = true;

  constructor(private readonly elementRef: ElementRef,
              private readonly ts: TwitterService,
              private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this._loadTwitterScript();
  }

  private _loadTwitterScript(): void {
    this.ts
      .loadScript()
      .subscribe((twitterData: any) => {
        this._updateTwitterScriptLoadingState();
        twitterData.widgets.createTweet(this.tweetId, this.elementRef.nativeElement, {});
      });
  }

  private _updateTwitterScriptLoadingState(): void {
    this.isTwitterScriptLoading = false;
    this.changeDetectorRef.detectChanges();
  }

}
