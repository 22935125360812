import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeModule} from './pages/home/home.module';
import {LayoutModuleModule} from './modules/layout-module/layout-module.module';
import {WriteContentModule} from './pages/write-content/write-content.module';
import {AuthGuard} from './_guards';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {environment} from '../environments/environment';
import {SecurityInterceptor} from './_services/interceptor/security-interceptor';
import './@types/enum';
import {EmbedTweetDirective} from './_directives/embed-tweet.directive';

@NgModule({
  declarations: [
    AppComponent,
    EmbedTweetDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    LayoutModuleModule,
    WriteContentModule,
    HttpClientModule
    // NgxTweetModule
  ],
  providers: [AuthGuard,
    {provide: 'environment.apiURL', useValue: environment.apiUrl},
    {provide: HTTP_INTERCEPTORS, useClass: SecurityInterceptor, multi: true},
  ],
  exports: [
    EmbedTweetDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
