import {Component, OnInit} from '@angular/core';
import {PostService} from '../../../_services/post.service';
import {IPostSummary} from '../../../@types/post';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html'
})
export class ArticlesComponent implements OnInit {

  public posts: IPostSummary[];

  constructor(private ps: PostService) {
  }

  ngOnInit() {
    this.ps.activePosts().subscribe((res) => {
      this.posts = res;
      console.log(res);
    });
  }

}
